/* =================================== */
/*  5. Elements
/* =================================== */

/*=== 5.1 Hero Background ===*/
.hero-wrap {
  position: relative;
  overflow: hidden;
  z-index: 0;
  .hero-mask,
  .hero-bg,
  .hero-bg-slideshow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .hero-bg-slideshow {
    position: fixed;
  }
  .hero-mask {
    z-index: 1;
  }
  .hero-content {
    position: relative;
    z-index: 3;
  }
  .hero-particles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .hero-bg-slideshow {
    z-index: 0;
  }
  .hero-bg {
    z-index: 0;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    transition: background-image 300ms ease-in 200ms;
    &.hero-bg-scroll {
      background-attachment: scroll;
    }
  }
  .hero-bg-slideshow {
    .hero-bg {
      background-attachment: inherit;
    }
    &.slick-slider {
      .slick-slide {
        .item {
          height: 100vh;
          width: 100%;
          min-height: 700px;
		  background-size: cover;
        }
      }
    }
  }
}

/*=== 5.2 List Style ===*/

.list-style-1 > li {
  position: relative;
  list-style-type: none;
  line-height: 24px;
  &:after {
    content: " ";
    position: absolute;
    top: 12px;
    left: -15px;
    border-color: #000;
    border-top: 1px solid;
    border-right: 1px solid;
    width: 6px;
    height: 6px;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
.rtl .list-style-1 > li {
  &:after {
    right: -15px;
    left: auto;
    border-color: #000;
    border-top: 0px;
    border-right: 0px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    width: 6px;
    height: 6px;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.list-style-2 {
  padding: 0;
}

.list-style-2 > li {
  list-style-type: none;
  border-bottom: 1px solid #eaeaea;
  padding-top: 12px;
  padding-bottom: 12px;
}
.list-style-2.list-style-light > li {
  border-bottom: 1px solid rgba(250, 250, 250, 0.12);
}

/*=== 5.3 Featured Box ===*/
.featured-box {
  box-sizing: border-box;
  position: relative;
  h3,
  h4 {
    font-size: 1.25rem;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  &:not(.style-5) .featured-box-icon {
    display: inline-block;
    font-size: 48px;
    min-width: 55px;
    min-height: 55px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0.8rem;
    color: #4c4d4d;
    border-radius: 0;
  }
  &.style-1,
  &.style-2,
  &.style-3 {
    padding-left: 50px;
    padding-top: 8px;
  }
  &.style-1 .featured-box-icon,
  &.style-2 .featured-box-icon,
  &.style-3 .featured-box-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0;
    font-size: 30px;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    text-align: center;
  }
  &.style-2 p {
    margin-left: -50px;
  }
  &.style-3 {
    padding-left: 90px;
    padding-top: 0px;
    .featured-box-icon {
      width: 70px;
      height: 70px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  &.style-4 {
    text-align: center;
    .featured-box-icon {
      margin: 0 auto 24px;
      margin: 0 auto 1.5rem;
      width: 120px;
      height: 120px;
      text-align: center;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      @include box-shadow(0px 0px 50px rgba(0, 0, 0, 0.03));
    }
  }

  &.style-5 {
    text-align: center;
    background: #fff;
    border: 1px solid #f0f2f3;
    @include box-shadow(0px 2px 5px rgba(0, 0, 0, 0.05));
    @include transition(all 0.3s ease-in-out);
    &:hover {
      border: 1px solid #ebeded;
      @include box-shadow(0px 5px 1.5rem rgba(0, 0, 0, 0.15));
    }
    h3 {
      background: #f1f5f6;
      font-size: 16px;
      padding: 8px 0;
      margin-bottom: 0px;
    }
    .featured-box-icon {
      font-size: 50px;
      margin: 44px 0px;
    }
  }
}

@mixin featured-box-reverse {
  text-align: right;
  &.style-1,
  &.style-2 {
    padding-right: 50px;
    padding-left: 0px;
    .featured-box-icon {
      left: auto;
      right: 0px;
    }
  }
  &.style-2 p {
    margin-right: -50px;
    margin-left: 0;
  }
  &.style-3 {
    padding-left: 0;
    padding-right: 90px;
    .featured-box-icon {
      left: auto;
      right: 0px;
    }
  }
}

.featured-box.featured-box-reverse,
.rtl .featured-box:not(.style-4) {
  @include featured-box-reverse;
}

@include media-breakpoint-up(sm) {
  .featured-box.featured-box-reverse-sm {
    @include featured-box-reverse;
  }
}

@include media-breakpoint-up(md) {
  .featured-box.featured-box-reverse-md {
    @include featured-box-reverse;
  }
}

@include media-breakpoint-up(lg) {
  .featured-box.featured-box-reverse-lg {
    @include featured-box-reverse;
  }
}
@include media-breakpoint-up(xl) {
  .featured-box.featured-box-reverse-xl {
    @include featured-box-reverse;
  }
}

/* 5.4 Nav */

.nav .nav-item .nav-link {
  color: #444;
}
.nav.nav-light .nav-item .nav-link {
  color: #ddd;
}
.nav:not(.nav-pills) .nav-item .nav-link.active,
.nav:not(.nav-pills) .nav-item .nav-link:hover {
  color: $primary-color;
}

.nav-pills .nav-link:not(.active):hover {
  color: $primary-color;
}
.nav-pills .nav-link.active,
.nav-pills.nav-light .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
}

.nav.nav-separator .nav-item .nav-link {
  position: relative;
}
.nav.nav-separator .nav-item + .nav-item .nav-link:after {
  height: 14px;
  width: 1px;
  content: " ";
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  @include translateY(-7px);
}
.rtl .nav.nav-separator .nav-item + .nav-item .nav-link:after {
  right: 0;
  left: auto;
}
.nav.nav-separator.nav-separator-light .nav-item + .nav-item .nav-link:after {
  background-color: rgba(250, 250, 250, 0.2);
}

.nav.nav-sm .nav-item .nav-link {
  font-size: 14px;
}

/*=== 5.5 Tabs ===*/

.nav-tabs {
  border-bottom: 1px solid #d7dee3;
  .nav-item .nav-link {
    border: 0;
    background: transparent;
    position: relative;
    border-radius: 0;
    padding: 0.6rem 1rem;
    color: #7b8084;
    white-space: nowrap !important;
    &.active {
      &:after {
        height: 2px;
        width: 100%;
        content: " ";
        background-color: $primary-color;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        @include translateY(-3px);
      }
      color: #0c2f55;
    }
    &:not(.active):hover {
      color: $primary-color;
    }
  }
  &.flex-column {
    border-right: 1px solid #d7dee3;
    border-bottom: 0px;
    padding: 1.5rem 0;
    .nav-item {
      .nav-link {
        border: 1px solid #d7dee3;
        border-right: 0px;
        background-color: #f6f7f8;
        font-size: 14px;
        padding: 0.75rem 1rem;
        color: #535b61;
      }
      &:first-of-type .nav-link {
        border-top-left-radius: 4px;
      }
      &:last-of-type .nav-link {
        border-bottom-left-radius: 4px;
      }
      .nav-link.active {
        &:after {
          height: 100%;
          width: 2px;
          background: #fff;
          right: -1px;
          left: auto;
        }
        background-color: transparent;
        color: $primary-color;
      }
    }
  }
}

.nav-tabs:not(.flex-column) {
  .nav-item {
    margin-bottom: 0px;
  }
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}

@include media-breakpoint-only(xs) {
  .nav-tabs .nav-item .nav-link {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
    font-size: 0.875rem;
  }
}

@include media-breakpoint-up(lg) {
  .search-input-2 {
    .form-control {
      border-radius: 0px;
    }
    .custom-select:not(.custom-select-sm) {
      border-radius: 0px;
      height: calc(3.05rem);
    }
    .btn {
      border-radius: 0px;
    }
    .form-group {
      &:first-child {
        .form-control,
        .custom-select {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      &:last-child .btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .form-control:focus,
    .custom-select:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
    .form-group .form-control,
    .custom-select {
      border-left: none;
      border-top: none;
      border-bottom: none;
    }
  }

  /* CSS hack for Chrome */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .search-input-2 {
      .custom-select:not(.custom-select-sm) {
        height: calc(3rem);
      }
      .btn {
        line-height: inherit;
      }
    }
  }

  /*  CSS hack for Firfox */
  @-moz-document url-prefix() {
    .search-input-2 {
      .custom-select:not(.custom-select-sm) {
        height: calc(3.05rem);
      }
      .btn {
        line-height: 1.4;
      }
    }
  }
}

/*=== 5.6 Owl Carousel ===*/

.owl-theme {
  &.single-slideshow {
    .owl-nav button {
      &.owl-prev,
      &.owl-next {
        font-size: 17px;
        width: 45px;
        height: 45px;
        top: calc(50% - 22px);
      }
      &.owl-prev {
        left: 10px;
      }
      &.owl-next {
        right: 10px;
      }
    }
    .owl-stage {
      padding: 0;
    }
  }
  .hero-wrap .hero-bg {
    background-attachment: inherit;
  }
  &.single-slideshow .owl-dots {
    position: absolute;
    bottom: 10px;
    width: 100%;
    .owl-dot {
      &.active span,
      &:hover span {
        border-color: $primary-color;
        @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
      }
      &.active span:after,
      &:hover span:after {
        background-color: $primary-color;
        @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
      }
    }
  }
  .owl-dots .owl-dot {
    &.active span,
    &:hover span {
      border-color: $primary-color;
      @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    }
    &.active span:after,
    &:hover span:after {
      background-color: $primary-color;
      @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    }
  }
  &.owl-light .owl-dots .owl-dot {
    span:after {
      background-color: rgba(250, 250, 250, 0.5);
    }
    &.active span:after,
    &:hover span:after {
      background-color: $primary-color;
      @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));
    }
  }
  &.owl-light {
    .owl-nav button {
      &.owl-prev,
      &.owl-next {
        background-color: rgba(250, 250, 250, 0.3);
        color: #fff;
      }
    }
    .owl-nav [class*="owl-"]:hover:not(.disabled) {
      background-color: rgba(250, 250, 250, 0.5);
      @include transition(all 0.3s ease-in-out);
    }
  }
}

/*=== 5.7 Countdown ===*/

.countdown div div div {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

/*=== 5.8 Magnific Popup ===*/

.mfp-bg {
  z-index: 1062;
}
.mfp-wrap {
  z-index: 1063;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.9;
}

/*=== 5.9 Portfolio ===*/

.portfolio {
  .portfolio-box {
    position: relative;
    .portfolio-img {
      position: relative;
      overflow: hidden;
    }
    .portfolio-overlay {
      text-align: center;
      display: -ms-flexbox !important;
      display: flex !important;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      @include transition(all 0.3s ease-in-out);
    }
    &:hover .portfolio-overlay {
      opacity: 1;
    }
    .portfolio-img img {
      @include transition(all 0.3s ease-in-out);
    }

    &:hover .portfolio-img img {
      -webkit-filter: blur(1px);
      filter: blur(1px);
      @include scale(1.08);
    }
    .portfolio-overlay-details {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .portfolio-details {
    padding: 1.5rem 0;
    text-align: center;
  }
}

.infobox {
  position: relative;
  padding: .5rem 1rem .5rem 60px;
  margin-bottom: 20px;
  border-radius: .375rem;
  text-align: left;

  &::before {
    content: "!";
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    border: 2px solid #dc3545;
    color: #dc3545;
    border-radius: 50%;
  }
}
